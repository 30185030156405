import React from 'react';
import { useState } from 'react';
import "./YourChoice.css";

import SliderWithSideButtons from './SliderWithSideButtons';
import CarouselPanel from './CarouselPanel';
import SliderWithSideButtonSingle from './SliderWithSideButtonSingle';
import ComparisonChart from './ComparisonChart';

import Carousel from 'react-material-ui-carousel';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TooltipMui from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { colorTheme } from "../AboutYou/AboutYou.js"

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

function YourChoice( {addressAPI, text, employer, scheme, region, dateOfBirth, dateJoinedScheme, dateLeftScheme, abs,
    salaryStartYear, salaries, partTime, isMemberOfMet, rankAtRetirement, 
    cpi, setCpi, prevSchemeName, currSchemeName, deferredAge, setDeferredAge,
    ageSPA, ageVRA, currentAge, retirementDates, retirementAges, careAges, setCareAges, careAgesMinimum,
    careAgesMaximum, dateLeftSchemeArray, leftScheme,
    maxCommutationPrevScheme, maxCommutationCurrScheme,setMaxCommutationPrevScheme, setMaxCommutationCurrScheme,
    commutationFactorPrevScheme, commutationFactorCurrScheme, setCommutationFactorPrevScheme, setCommutationFactorCurrScheme,
    chosenCommutationProportionPrevScheme, setChosenCommutationProportionPrevScheme,
    chosenCommutationProportionCurrScheme, setChosenCommutationProportionCurrScheme,
    annualPensionPrevScheme, annualPensionCurrScheme, finalSalary,setFinalSalary, titles, minDeferredAge, maxDeferredAge,
    setAnnualPensionPrevScheme, setAnnualPensionCurrScheme,
    annualPensionNominal, annualPensionReal, annualPensionProportional, lumpSumNominal, lumpSumReal, lumpSumProportional, lumpSumComparisonChart,
    setAnnualPensionNominal, setAnnualPensionReal, setAnnualPensionProportional, setLumpSumNominal, setLumpSumReal, setLumpSumProportional,
    transitionYear
      } ) {


    
    const [local_deferredAge, local_setDeferredAge] = useState(deferredAge)
    const [local_careAges, local_setCareAges] = useState(careAges)
    const [local_maxCommutationPrevScheme, local_setMaxCommutationPrevScheme] = useState(maxCommutationPrevScheme)
    const [local_maxCommutationCurrScheme, local_setMaxCommutationCurrScheme] = useState(maxCommutationCurrScheme)
    const [local_commutationFactorPrevScheme, local_setCommutationFactorPrevScheme] = useState(commutationFactorPrevScheme)
    const [local_commutationFactorCurrScheme, local_setCommutationFactorCurrScheme] = useState(commutationFactorCurrScheme)
    const [local_chosenCommutationProportionPrevScheme, local_setChosenCommutationProportionPrevScheme] = useState(chosenCommutationProportionPrevScheme)
    const [local_chosenCommutationProportionCurrScheme, local_setChosenCommutationProportionCurrScheme] = useState(chosenCommutationProportionCurrScheme)
    const [local_annualPensionPrevScheme, local_setAnnualPensionPrevScheme] = useState(annualPensionPrevScheme)
    const [local_annualPensionCurrScheme, local_setAnnualPensionCurrScheme] = useState(annualPensionCurrScheme)
    const [local_finalSalary, local_setFinalSalary] = useState(finalSalary)
    const [local_cpi, local_setCpi] = useState(cpi)

    const [local_annualPensionNominal, local_setAnnualPensionNominal] = useState(annualPensionNominal)
    const [local_annualPensionReal, local_setAnnualPensionReal] = useState(annualPensionReal)
    const [local_annualPensionProportional, local_setAnnualPensionProportional] = useState(annualPensionProportional)
    const [local_lumpSumNominal, local_setLumpSumNominal] = useState(lumpSumNominal)
    const [local_lumpSumReal, local_setLumpSumReal] = useState(lumpSumReal)
    const [local_lumpSumProportional, local_setLumpSumProportional] = useState(lumpSumProportional)

    const [local_lumpSumComparisonChart, local_setLumpSumComparisonChart] = useState(lumpSumComparisonChart)

    const [local_dateLeftSchemeArray, local_setDateLeftSchemeArray] = useState(dateLeftSchemeArray)

    const [viewOption, setViewOption] = useState(0)
    const [currentPanel, setCurrentPanel] = useState(titles.length === 1 ? 0 : titles.length-1)
    const [tableData, setTableData] = useState([local_annualPensionNominal, local_lumpSumNominal])
    const [loading, setLoading] = useState(false)
    const [isCpiEdit, setIsCpiEdit] = useState(false)
    const [overwriteSalary, setOverwriteSalary] = useState(false)

    const [expanded, setExpanded] = useState('panel2');

    const [local_chosenCommutationProportionPrevSchemeAdj, local_setChosenCommutationProportionPrevSchemeAdj] = useState(chosenCommutationProportionPrevScheme.map(function(e,i){return e/(maxCommutationPrevScheme[i] === 0 ? 1 : maxCommutationPrevScheme[i])}))
    const [local_chosenCommutationProportionCurrSchemeAdj, local_setChosenCommutationProportionCurrSchemeAdj] = useState(chosenCommutationProportionCurrScheme.map(function(e,i){return e/(maxCommutationCurrScheme[i] === 0 ? 1 : maxCommutationCurrScheme[i])}))

    const [local_transitionYear, local_setTransitionYear] = useState(transitionYear);

    // console.log(
    //     { 'prev': {
    //         'local_maxCommutationPrevScheme': local_maxCommutationPrevScheme,
    //         'chosenCommutationProportionPrevScheme': chosenCommutationProportionPrevScheme,
    //         'local_chosenCommutationProportionPrevScheme': local_chosenCommutationProportionPrevScheme,
    //         'local_chosenCommutationProportionPrevSchemeAdj': local_chosenCommutationProportionPrevSchemeAdj},
    //     'curr': {
    //         'local_maxCommutationCurrScheme': local_maxCommutationCurrScheme,
    //         'chosenCommutationProportionCurrScheme': chosenCommutationProportionCurrScheme,
    //         'local_chosenCommutationProportionCurrScheme': local_chosenCommutationProportionCurrScheme,
    //         'local_chosenCommutationProportionCurrSchemeAdj': local_chosenCommutationProportionCurrSchemeAdj}
    //     })
        
    const constants = {
        'prevSchemeName' : prevSchemeName,
        'currSchemeName' : currSchemeName,
        'dateOfBirth' : dateOfBirth,
        'dateJoinedScheme' : dateJoinedScheme,
        'ABS' : abs,
        'salaryStartYear': salaryStartYear,
        'salaries' : salaries,
        'partTimes' : partTime,
        'SPA' : ageSPA,
        'VRA' : ageVRA,
        'currentAge': currentAge
    }

    const cpiMin = 0
    const cpiMax = 0.06
    const cpiStepSize = 0.0005
    const cpiDisplayMultiplicationFactor = 100
    const cpiRoundingDP = 2
    const cpiInformation = "This is a measure of the annual salary increases you will receive until leaving the scheme. Your pension is directly linked to salary increases."

    const commutationMin = 0.000
    const commutationStepSize = 0.01
    const commutationDisplayMultiplicationFactor = 100
    const commutationRoundingDP = 0

    const viewOptionInfo = text['viewOption']
    
    const commutation1987Information = <Typography component="div" sx={{display:'block',textAlign:'left',fontSize:'16px', marginTop:'2px', marginBottom:'5px'} }>Members are able to exchange (commute) some of their pension for a cash lump sum. The amount of pension that can be commuted in the legacy Scheme is subject to the limits set out in the Regulations. Please refer to the Regulations for full details of the commutation limits. This calculator applies the limits described above.<br/><br/>Please note that restrictions on pension commutation are also imposed by the pension taxation regime under Finance Act 2004. The main taxation restriction is that for a lump sum to be authorised under the tax rules it must not exceed 25% of the total value of benefits crystallised. Please refer to HMRC guidance for further information. This calculator does not make any allowance for the pensions tax regime.</Typography> 
    const commutation2015Information = <Typography component="div" sx={{display:'block',textAlign:'left',fontSize:'16px', marginTop:'2px', marginBottom:'5px'} }> Members are generally able to exchange (commute) up to 35.7% of their 2015 Scheme pension for a cash lump sum (limits set by HMRC). Setting this slider to “100” will therefore illustrate the lump sum and reduced pension you will receive after commuting 35.7% of your 2015 Scheme pension.</Typography> 
    
    const CAREAgeInformation = text['careAge']
    const deferredAgeInformation = text['deferredAge']

    const titleInfoTextComparisonChart = "This chart compares the benefits you will receive at key retirement ages based on switching to the reformed pension scheme in 2015 and 2022. The pension and lump sum amounts for each retirement age reflect the cash lump sum chosen in each of the charts that follow. To alter any of these assumptions, please change them on their respective chart."
    const legendLabelsCarousel = [{key: "Move to CARE in 2015", color: "rgb(68,114,196)", name: "Move to reformed Scheme in 2015"},
                                     {key: "Move to CARE in 2022", color: "rgb(237,125,49)", name: "Move to reformed Scheme in 2022"},]


    const titleInfoTextRetireAge55 = text['titleInfoTextRetireAge55']        
    const titleInfoTextRetireAge60 = text['titleInfoTextRetireAge60']        
    const titleInfoTextRetireService25 = text['titleInfoTextRetireService25']       
    const titleInfoTextRetireService30 = text['titleInfoTextRetireService30']        
    const titleInfoTextRetireDeferred = text['titleInfoTextRetireDeferred']
    const titleInfoTextRetireImmediate = text['titleInfoTextRetireImmediate']
    const titleInfoTextAlreadyLeft = text['titleInfoTextAlreadyLeft']
    const titleInfoTextRetireAge65 = text['titleInfoTextRetireAge65'] 
    const titleInfoTextRetireNPA = text['titleInfoTextRetireNPA'] 
    const titleInfoTextRetireSPA = text['titleInfoTextRetireSPA'] 

    const titleInfoText = {'Retirement at age 55' : titleInfoTextRetireAge55,
                        'Retirement at age 60' : titleInfoTextRetireAge60,
                        'Retirement at 25 years of pensionable service' : titleInfoTextRetireService25,
                        'Retirement at 30 years of pensionable service' : titleInfoTextRetireService30,
                        'Leave scheme' : titleInfoTextRetireDeferred,
                        'Immediate retirement' : titleInfoTextRetireImmediate,
                        'Left scheme' : titleInfoTextAlreadyLeft,
                        'Retirement at age 50 with 25 years of pensionable service': titleInfoTextRetireService25, 
                        'Retirement at age 50 with 26 years of pensionable service': titleInfoTextRetireService25, 
                        'Retirement at age 50 with 27 years of pensionable service': titleInfoTextRetireService25, 
                        'Retirement at age 50 with 28 years of pensionable service': titleInfoTextRetireService25, 
                        'Retirement at age 50 with 29 years of pensionable service': titleInfoTextRetireService25, 
                        'Retirement at age 50 with 30 years of pensionable service': titleInfoTextRetireService30,
                        "Retirement at age 65" : titleInfoTextRetireAge65, 
                        "Retirement at NPA" : titleInfoTextRetireNPA, 
                        "Retirement at SPA" : titleInfoTextRetireSPA,
                     }

    const requestUpdatedData_cpi = ( newCpi ) => {
        setLoading(true)

        const scenarioParameters = {  
            'retirementDate' : retirementDates,
            'retirementAge' : retirementAges,
            'careAge' : local_careAges,
            'careAgeMinimum' : careAgesMinimum,
            'careAgeMaximum' : careAgesMaximum,
            'dateLeftScheme' : local_dateLeftSchemeArray,
            'leftScheme' : leftScheme,
            'deferredAge' : local_deferredAge,
            'maxCommutationPrevScheme' : local_maxCommutationPrevScheme,
            'commutationFactorPrevScheme' : local_commutationFactorPrevScheme,
            'maxCommutationCurrScheme' : local_maxCommutationCurrScheme,
            'commutationFactorCurrScheme' : local_commutationFactorCurrScheme,
            'annualPensionPrevScheme' : local_annualPensionPrevScheme,
            'annualPensionCurrScheme' : local_annualPensionCurrScheme,
            'finalSalary' : local_finalSalary,
            'chosenCommutationProportionPrevScheme' : local_chosenCommutationProportionPrevScheme,
            'chosenCommutationProportionCurrScheme' : local_chosenCommutationProportionCurrScheme         
        }
                 
        const data = {
            "scenarioParameters" : scenarioParameters,
            "constants" : constants,
            "cpi" : newCpi,
            "parameter": "cpi",
            "currentPanel": currentPanel,
            "titles":titles,
            "overwriteSalary":overwriteSalary
        }

        const requestOptions = {
            method: 'Post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        }

        let address = addressAPI + "api/update_panel"
        fetch(address, requestOptions)
        .then(response => response.json())
        .then(function (data) {
            local_setDeferredAge(data['scenarioParameters']['deferredAge'])
            local_setCareAges(data['scenarioParameters']['careAge'])
            local_setMaxCommutationPrevScheme(data['scenarioParameters']['maxCommutationPrevScheme'])
            local_setMaxCommutationCurrScheme(data['scenarioParameters']['maxCommutationCurrScheme'])              
            local_setCommutationFactorPrevScheme(data['scenarioParameters']['commutationFactorPrevScheme'])
            local_setCommutationFactorCurrScheme(data['scenarioParameters']['commutationFactorCurrScheme'])
            local_setChosenCommutationProportionPrevScheme(data['scenarioParameters']['chosenCommutationProportionPrevScheme'])
            local_setChosenCommutationProportionCurrScheme(data['scenarioParameters']['chosenCommutationProportionCurrScheme'])
            local_setAnnualPensionPrevScheme(data['scenarioParameters']['annualPensionPrevScheme'])
            local_setAnnualPensionCurrScheme(data['scenarioParameters']['annualPensionCurrScheme'])
            local_setFinalSalary(data['scenarioParameters']['finalSalary'])
            local_setCpi(data['cpi'])
            local_setAnnualPensionNominal(data['annualPension_nominal'])
            local_setAnnualPensionReal(data['annualPension_real'])
            local_setAnnualPensionProportional(data['annualPension_proportion'])
            local_setLumpSumNominal(data['lumpSum_nominal'])
            local_setLumpSumReal(data['lumpSum_real'])
            local_setLumpSumProportional(data['lumpSum_proportion'])
            local_setLumpSumComparisonChart(data['lumpSum_comparison'])
            local_setDateLeftSchemeArray(data['scenarioParameters']['dateLeftScheme'])
            setLoading(false)
        })
    }

    const requestUpdatedData_prevSchemeCommutation = ( newChosenCommutationProportionPrevScheme ) => {
        setLoading(true)

        const scenarioParameters = {  
            'retirementDate' : retirementDates,
            'retirementAge' : retirementAges,
            'careAge' : local_careAges,
            'careAgeMinimum' : careAgesMinimum,
            'careAgeMaximum' : careAgesMaximum,
            'dateLeftScheme' : local_dateLeftSchemeArray,
            'leftScheme' : leftScheme,
            'deferredAge' : local_deferredAge,
            'maxCommutationPrevScheme' : local_maxCommutationPrevScheme,
            'commutationFactorPrevScheme' : local_commutationFactorPrevScheme,
            'maxCommutationCurrScheme' : local_maxCommutationCurrScheme,
            'commutationFactorCurrScheme' : local_commutationFactorCurrScheme,
            'annualPensionPrevScheme' : local_annualPensionPrevScheme,
            'annualPensionCurrScheme' : local_annualPensionCurrScheme,
            'finalSalary' : local_finalSalary,
            'chosenCommutationProportionPrevScheme' : newChosenCommutationProportionPrevScheme.map(function(e,i){return e * local_maxCommutationPrevScheme[i]}),
            'chosenCommutationProportionCurrScheme' : local_chosenCommutationProportionCurrScheme
        }
                 
        const data = {
            "scenarioParameters" : scenarioParameters,
            "constants" : constants,
            "cpi" : local_cpi,
            "parameter": "chosenCommutationProportionPrevScheme",
            "currentPanel": currentPanel,
            "titles":titles,
            "overwriteSalary":overwriteSalary
        }

        const requestOptions = {
            method: 'Post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        }

        let address = addressAPI + "api/update_panel"
        fetch(address, requestOptions)
        .then(response => response.json())
        .then(function (data) {
            local_setDeferredAge(data['scenarioParameters']['deferredAge'])
            local_setCareAges(data['scenarioParameters']['careAge'])
            local_setMaxCommutationPrevScheme(data['scenarioParameters']['maxCommutationPrevScheme'])
            local_setMaxCommutationCurrScheme(data['scenarioParameters']['maxCommutationCurrScheme'])              
            local_setCommutationFactorPrevScheme(data['scenarioParameters']['commutationFactorPrevScheme'])
            local_setCommutationFactorCurrScheme(data['scenarioParameters']['commutationFactorCurrScheme'])
            local_setChosenCommutationProportionPrevScheme(data['scenarioParameters']['chosenCommutationProportionPrevScheme'])
            local_setChosenCommutationProportionCurrScheme(data['scenarioParameters']['chosenCommutationProportionCurrScheme'])
            local_setAnnualPensionPrevScheme(data['scenarioParameters']['annualPensionPrevScheme'])
            local_setAnnualPensionCurrScheme(data['scenarioParameters']['annualPensionCurrScheme'])
            local_setFinalSalary(data['scenarioParameters']['finalSalary'])
            local_setCpi(data['cpi'])
            local_setAnnualPensionNominal(data['annualPension_nominal'])
            local_setAnnualPensionReal(data['annualPension_real'])
            local_setAnnualPensionProportional(data['annualPension_proportion'])
            local_setLumpSumNominal(data['lumpSum_nominal'])
            local_setLumpSumReal(data['lumpSum_real'])
            local_setLumpSumProportional(data['lumpSum_proportion'])
            local_setLumpSumComparisonChart(data['lumpSum_comparison'])
            local_setDateLeftSchemeArray(data['scenarioParameters']['dateLeftScheme'])
            setLoading(false)
        })
    }

    const requestUpdatedData_currSchemeCommutation = ( newChosenCommutationProportionCurrScheme ) => {
        setLoading(true)

        const scenarioParameters = {  
            'retirementDate' : retirementDates,
            'retirementAge' : retirementAges,
            'careAge' : local_careAges,
            'careAgeMinimum' : careAgesMinimum,
            'careAgeMaximum' : careAgesMaximum,
            'dateLeftScheme' : local_dateLeftSchemeArray,
            'leftScheme' : leftScheme,
            'deferredAge' : local_deferredAge,
            'maxCommutationPrevScheme' : local_maxCommutationPrevScheme,
            'commutationFactorPrevScheme' : local_commutationFactorPrevScheme,
            'maxCommutationCurrScheme' : local_maxCommutationCurrScheme,
            'commutationFactorCurrScheme' : local_commutationFactorCurrScheme,
            'annualPensionPrevScheme' : local_annualPensionPrevScheme,
            'annualPensionCurrScheme' : local_annualPensionCurrScheme,
            'finalSalary' : local_finalSalary,
            'chosenCommutationProportionPrevScheme' : local_chosenCommutationProportionPrevScheme,
            'chosenCommutationProportionCurrScheme' : newChosenCommutationProportionCurrScheme.map(function(e,i){return e * local_maxCommutationCurrScheme[i]})
        }
                 
        const data = {
            "scenarioParameters" : scenarioParameters,
            "constants" : constants,
            "cpi" : local_cpi,
            "parameter": "chosenCommutationProportionCurrScheme",
            "currentPanel": currentPanel,
            "titles":titles,
            "overwriteSalary":overwriteSalary
        }

        const requestOptions = {
            method: 'Post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        }

        let address = addressAPI + "api/update_panel"
        fetch(address, requestOptions)
        .then(response => response.json())
        .then(function (data) {
            local_setDeferredAge(data['scenarioParameters']['deferredAge'])
            local_setCareAges(data['scenarioParameters']['careAge'])
            local_setMaxCommutationPrevScheme(data['scenarioParameters']['maxCommutationPrevScheme'])
            local_setMaxCommutationCurrScheme(data['scenarioParameters']['maxCommutationCurrScheme'])              
            local_setCommutationFactorPrevScheme(data['scenarioParameters']['commutationFactorPrevScheme'])
            local_setCommutationFactorCurrScheme(data['scenarioParameters']['commutationFactorCurrScheme'])
            local_setChosenCommutationProportionPrevScheme(data['scenarioParameters']['chosenCommutationProportionPrevScheme'])
            local_setChosenCommutationProportionCurrScheme(data['scenarioParameters']['chosenCommutationProportionCurrScheme'])
            local_setAnnualPensionPrevScheme(data['scenarioParameters']['annualPensionPrevScheme'])
            local_setAnnualPensionCurrScheme(data['scenarioParameters']['annualPensionCurrScheme'])
            local_setFinalSalary(data['scenarioParameters']['finalSalary'])
            local_setCpi(data['cpi'])
            local_setAnnualPensionNominal(data['annualPension_nominal'])
            local_setAnnualPensionReal(data['annualPension_real'])
            local_setAnnualPensionProportional(data['annualPension_proportion'])
            local_setLumpSumNominal(data['lumpSum_nominal'])
            local_setLumpSumReal(data['lumpSum_real'])
            local_setLumpSumProportional(data['lumpSum_proportion'])
            local_setLumpSumComparisonChart(data['lumpSum_comparison'])
            local_setDateLeftSchemeArray(data['scenarioParameters']['dateLeftScheme'])
            setLoading(false)
        })
    }

    const requestUpdatedData_careAges = ( newCareAges ) => {
        setLoading(true)

        const scenarioParameters = {  
            'retirementDate' : retirementDates,
            'retirementAge' : retirementAges,
            'careAge' : newCareAges,
            'careAgeMinimum' : careAgesMinimum,
            'careAgeMaximum' : careAgesMaximum,
            'dateLeftScheme' : local_dateLeftSchemeArray,
            'leftScheme' : leftScheme,
            'deferredAge' : local_deferredAge,
            'maxCommutationPrevScheme' : local_maxCommutationPrevScheme,
            'commutationFactorPrevScheme' : local_commutationFactorPrevScheme,
            'maxCommutationCurrScheme' : local_maxCommutationCurrScheme,
            'commutationFactorCurrScheme' : local_commutationFactorCurrScheme,
            'annualPensionPrevScheme' : local_annualPensionPrevScheme,
            'annualPensionCurrScheme' : local_annualPensionCurrScheme,
            'finalSalary' : local_finalSalary,
            'chosenCommutationProportionPrevScheme' : local_chosenCommutationProportionPrevScheme,
            'chosenCommutationProportionCurrScheme' : local_chosenCommutationProportionCurrScheme 
        }
                 
        const data = {
            "scenarioParameters" : scenarioParameters,
            "constants" : constants,
            "cpi" : local_cpi,
            "parameter": "careAge",
            "currentPanel": currentPanel,
            "titles":titles,
            "overwriteSalary":overwriteSalary
        }

        const requestOptions = {
            method: 'Post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        }

        let address = addressAPI + "api/update_panel"
        fetch(address, requestOptions)
        .then(response => response.json())
        .then(function (data) {
            local_setDeferredAge(data['scenarioParameters']['deferredAge'])
            local_setCareAges(data['scenarioParameters']['careAge'])
            local_setMaxCommutationPrevScheme(data['scenarioParameters']['maxCommutationPrevScheme'])
            local_setMaxCommutationCurrScheme(data['scenarioParameters']['maxCommutationCurrScheme'])              
            local_setCommutationFactorPrevScheme(data['scenarioParameters']['commutationFactorPrevScheme'])
            local_setCommutationFactorCurrScheme(data['scenarioParameters']['commutationFactorCurrScheme'])
            local_setChosenCommutationProportionPrevScheme(data['scenarioParameters']['chosenCommutationProportionPrevScheme'])
            local_setChosenCommutationProportionCurrScheme(data['scenarioParameters']['chosenCommutationProportionCurrScheme'])
            local_setAnnualPensionPrevScheme(data['scenarioParameters']['annualPensionPrevScheme'])
            local_setAnnualPensionCurrScheme(data['scenarioParameters']['annualPensionCurrScheme'])
            local_setFinalSalary(data['scenarioParameters']['finalSalary'])
            local_setCpi(data['cpi'])
            local_setAnnualPensionNominal(data['annualPension_nominal'])
            local_setAnnualPensionReal(data['annualPension_real'])
            local_setAnnualPensionProportional(data['annualPension_proportion'])
            local_setLumpSumNominal(data['lumpSum_nominal'])
            local_setLumpSumReal(data['lumpSum_real'])
            local_setLumpSumProportional(data['lumpSum_proportion'])
            local_setLumpSumComparisonChart(data['lumpSum_comparison'])
            local_setDateLeftSchemeArray(data['scenarioParameters']['dateLeftScheme'])
            setLoading(false)
        })
    }

    const requestUpdatedData_defferedAges = ( newDefferedAges ) => {
        setLoading(true)

        const scenarioParameters = {  
            'retirementDate' : retirementDates,
            'retirementAge' : retirementAges,
            'careAge' : local_careAges,
            'careAgeMinimum' : careAgesMinimum,
            'careAgeMaximum' : careAgesMaximum,
            'dateLeftScheme' : local_dateLeftSchemeArray,
            'leftScheme' : leftScheme,
            'deferredAge' : newDefferedAges,
            'maxCommutationPrevScheme' : local_maxCommutationPrevScheme,
            'commutationFactorPrevScheme' : local_commutationFactorPrevScheme,
            'maxCommutationCurrScheme' : local_maxCommutationCurrScheme,
            'commutationFactorCurrScheme' : local_commutationFactorCurrScheme,
            'annualPensionPrevScheme' : local_annualPensionPrevScheme,
            'annualPensionCurrScheme' : local_annualPensionCurrScheme,
            'finalSalary' : local_finalSalary,
            'chosenCommutationProportionPrevScheme' : local_chosenCommutationProportionPrevScheme,
            'chosenCommutationProportionCurrScheme' : local_chosenCommutationProportionCurrScheme  
        }
                 
        const data = {
            "scenarioParameters" : scenarioParameters,
            "constants" : constants,
            "cpi" : local_cpi,
            "parameter": "deferredAge",
            "currentPanel": currentPanel,
            "titles":titles,
            "overwriteSalary":overwriteSalary
        }

        const requestOptions = {
            method: 'Post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        }

        let address = addressAPI + "api/update_panel"
        fetch(address, requestOptions)
        .then(response => response.json())
        .then(function (data) {
            local_setDeferredAge(data['scenarioParameters']['deferredAge'])
            local_setCareAges(data['scenarioParameters']['careAge'])
            local_setMaxCommutationPrevScheme(data['scenarioParameters']['maxCommutationPrevScheme'])
            local_setMaxCommutationCurrScheme(data['scenarioParameters']['maxCommutationCurrScheme'])              
            local_setCommutationFactorPrevScheme(data['scenarioParameters']['commutationFactorPrevScheme'])
            local_setCommutationFactorCurrScheme(data['scenarioParameters']['commutationFactorCurrScheme'])
            local_setChosenCommutationProportionPrevScheme(data['scenarioParameters']['chosenCommutationProportionPrevScheme'])
            local_setChosenCommutationProportionCurrScheme(data['scenarioParameters']['chosenCommutationProportionCurrScheme'])
            local_setAnnualPensionPrevScheme(data['scenarioParameters']['annualPensionPrevScheme'])
            local_setAnnualPensionCurrScheme(data['scenarioParameters']['annualPensionCurrScheme'])
            local_setFinalSalary(data['scenarioParameters']['finalSalary'])
            local_setCpi(data['cpi'])
            local_setAnnualPensionNominal(data['annualPension_nominal'])
            local_setAnnualPensionReal(data['annualPension_real'])
            local_setAnnualPensionProportional(data['annualPension_proportion'])
            local_setLumpSumNominal(data['lumpSum_nominal'])
            local_setLumpSumReal(data['lumpSum_real'])
            local_setLumpSumProportional(data['lumpSum_proportion'])
            local_setLumpSumComparisonChart(data['lumpSum_comparison'])
            local_setDateLeftSchemeArray(data['scenarioParameters']['dateLeftScheme'])
            setLoading(false)
        })
    }

    const requestUpdatedData_overwriteSalary = ( newOverwriteSalary ) => {
        setLoading(true)

        const scenarioParameters = {  
            'retirementDate' : retirementDates,
            'retirementAge' : retirementAges,
            'careAge' : local_careAges,
            'careAgeMinimum' : careAgesMinimum,
            'careAgeMaximum' : careAgesMaximum,
            'dateLeftScheme' : local_dateLeftSchemeArray,
            'leftScheme' : leftScheme,
            'deferredAge' : local_deferredAge,
            'maxCommutationPrevScheme' : local_maxCommutationPrevScheme,
            'commutationFactorPrevScheme' : local_commutationFactorPrevScheme,
            'maxCommutationCurrScheme' : local_maxCommutationCurrScheme,
            'commutationFactorCurrScheme' : local_commutationFactorCurrScheme,
            'annualPensionPrevScheme' : local_annualPensionPrevScheme,
            'annualPensionCurrScheme' : local_annualPensionCurrScheme,
            'finalSalary' : local_finalSalary,
            'chosenCommutationProportionPrevScheme' : local_chosenCommutationProportionPrevScheme,
            'chosenCommutationProportionCurrScheme' : local_chosenCommutationProportionCurrScheme 
        }
                 
        const data = {
            "scenarioParameters" : scenarioParameters,
            "constants" : constants,
            "cpi" : local_cpi,
            "parameter" : "cpi",
            "currentPanel" : currentPanel,
            "titles": titles,
            "overwriteSalary" : newOverwriteSalary
        }

        const requestOptions = {
            method: 'Post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        }

        let address = addressAPI + "api/update_panel"
        fetch(address, requestOptions)
        .then(response => response.json())
        .then(function (data) {
            local_setDeferredAge(data['scenarioParameters']['deferredAge'])
            local_setCareAges(data['scenarioParameters']['careAge'])
            local_setMaxCommutationPrevScheme(data['scenarioParameters']['maxCommutationPrevScheme'])
            local_setMaxCommutationCurrScheme(data['scenarioParameters']['maxCommutationCurrScheme'])              
            local_setCommutationFactorPrevScheme(data['scenarioParameters']['commutationFactorPrevScheme'])
            local_setCommutationFactorCurrScheme(data['scenarioParameters']['commutationFactorCurrScheme'])
            local_setChosenCommutationProportionPrevScheme(data['scenarioParameters']['chosenCommutationProportionPrevScheme'])
            local_setChosenCommutationProportionCurrScheme(data['scenarioParameters']['chosenCommutationProportionCurrScheme'])
            local_setAnnualPensionPrevScheme(data['scenarioParameters']['annualPensionPrevScheme'])
            local_setAnnualPensionCurrScheme(data['scenarioParameters']['annualPensionCurrScheme'])
            local_setFinalSalary(data['scenarioParameters']['finalSalary'])
            local_setCpi(data['cpi'])
            local_setAnnualPensionNominal(data['annualPension_nominal'])
            local_setAnnualPensionReal(data['annualPension_real'])
            local_setAnnualPensionProportional(data['annualPension_proportion'])
            local_setLumpSumNominal(data['lumpSum_nominal'])
            local_setLumpSumReal(data['lumpSum_real'])
            local_setLumpSumProportional(data['lumpSum_proportion'])
            local_setLumpSumComparisonChart(data['lumpSum_comparison'])
            local_setDateLeftSchemeArray(data['scenarioParameters']['dateLeftScheme'])
            setLoading(false)
        })
    }

    const handleViewOptionChange = (viewOption) => {
        setViewOption(viewOption)
        if (viewOption === 0) {
            setTableData([local_annualPensionNominal, local_lumpSumNominal])
        } else if (viewOption === 1) {
            setTableData([local_annualPensionReal, local_lumpSumReal])
        } else {
            setTableData([local_annualPensionProportional, local_lumpSumProportional])
        }
    }

    const handleCpiEditClick = () => {
        setIsCpiEdit(!isCpiEdit)
    }

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

      const handleOverwriteSalary = () => {
        requestUpdatedData_overwriteSalary(!overwriteSalary)
        setOverwriteSalary(!overwriteSalary)
    }

    const prevSchemeCommutationLabel = "Cash lump sum in " + scheme + " Scheme (%)";

    return (
        <div className="yourchoice__graph__container">
            {loading === true ? (
                <div id="loading"/>
            ) : (
                null
            )}
                
            <div className="carousel__container">
                <div className="dataviewoption__container">
                {viewOption === 0 ? (
                    <div className="option__box">
                        <span className="optionchoice__active" onClick={() => handleViewOptionChange(0)}>Allowing for inflation</span>
                        <span onClick={() => handleViewOptionChange(1)}>In today’s money terms</span>
                        {/* <span onClick={() => handleViewOptionChange(2)}>Proportional</span> */}
                        <TooltipMui title={<Typography fontSize={'16px'}>{viewOptionInfo}</Typography>}>
                            <IconButton id="viewoptions__infobutton" >
                            <InfoIcon id="viewoptions__infobutton" />
                            </IconButton>
                        </TooltipMui >
                    </div>
                ) : viewOption === 1 ? (
                <div className="option__box">
                        <span onClick={() => handleViewOptionChange(0)}>Allowing for inflation</span>
                        <span className="optionchoice__active" onClick={() => handleViewOptionChange(1)}>In today’s money terms</span>
                        {/* <span onClick={() => handleViewOptionChange(2)}>Proportional</span> */}
                        <TooltipMui title={<Typography fontSize={'16px'}>{viewOptionInfo}</Typography>}>
                            <IconButton id="viewoptions__infobutton" >
                            <InfoIcon id="viewoptions__infobutton" />
                            </IconButton>
                        </TooltipMui >
                    </div>
                ) : (
                    <div className="option__box">
                        <span onClick={() => handleViewOptionChange(0)}>Allowing for inflation</span>
                        <span onClick={() => handleViewOptionChange(1)}>In today’s money terms</span>
                        {/* <span className="optionchoice__active" onClick={() => handleViewOptionChange(2)}>Proportional</span> */}
                        <TooltipMui title={<Typography fontSize={'16px'}>{viewOptionInfo}</Typography>}>
                            <IconButton id="viewoptions__infobutton" >
                            <InfoIcon id="viewoptions__infobutton" />
                            </IconButton>
                        </TooltipMui >
                    </div>
                )}
                </div>

                <Accordion expanded={expanded==='panel1'} onChange={handleAccordionChange('panel1')} style={{marginTop:"20px"}}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Your Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper} style={{width:"60%", position:"relative", left:"20%"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Your Details</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>                                               
                                </TableHead>
                                <TableBody>
                                    {/* <TableRow>
                                        <TableCell>Profession</TableCell>
                                        <TableCell>{employer === 'Police' ? 'Police Officer' : employer}</TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                        <TableCell>Scheme</TableCell>
                                        <TableCell>{scheme}</TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <TableCell>Region</TableCell>
                                        <TableCell>{region}</TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                        <TableCell>Date Of Birth</TableCell>
                                        <TableCell>{moment(dateOfBirth).format("DD/MM/YYYY")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Date Joined Scheme</TableCell>
                                        <TableCell>{moment(dateJoinedScheme).format("DD/MM/YYYY")}</TableCell>
                                    </TableRow>
                                    {leftScheme === null || leftScheme[0] === false || dateLeftSchemeArray[0] === null ? (
                                    <TableRow>
                                        <TableCell>Date Left Scheme</TableCell>
                                        <TableCell>N/A</TableCell>
                                    </TableRow>
                                    ) : (
                                    <TableRow>
                                        <TableCell>Date Left Scheme</TableCell>
                                        <TableCell>{moment(dateLeftSchemeArray[0]).format("DD/MM/YYYY")}</TableCell>
                                    </TableRow>
                                    )}
                                    {abs > 0 ? (
                                    <TableRow>
                                        <TableCell>Annual benefit statement</TableCell>
                                        <TableCell>£ {abs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    </TableRow>
                                    ) : (
                                    <TableRow>
                                    </TableRow>
                                    )}
                                    {abs > 0 ? (
                                    <TableRow>
                                        <TableCell>Annual benefit statement Year</TableCell>
                                        <TableCell>{salaryStartYear}</TableCell>
                                    </TableRow>
                                    ) : (
                                    <TableRow>
                                    </TableRow> 
                                    )}
                                    <TableRow>
                                        <TableCell>Current Salary</TableCell>
                                        <TableCell>£ {salaries[salaries.length-1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>

                                    </TableRow> 
                                    {salaries[0] !== "" ?
                                    (<TableRow>
                                        <TableCell>Previous Salary</TableCell>
                                        <TableCell>£ {salaries[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>

                                    </TableRow>) : (
                                        <TableRow>
                                        <TableCell>Previous Salary</TableCell>
                                        <TableCell>N/A</TableCell>
                                    </TableRow>
                                    ) }

                                    {region === "England & Wales" &&                                   
                                    <TableRow>
                                        <TableCell>Member of the Metropolitan Police?</TableCell>
                                        <TableCell>{isMemberOfMet !== true ? ("No") : ("Yes")}</TableCell>
                                    </TableRow>}
                                    <TableRow>

                                        <TableCell>Rank</TableCell>
                                        <TableCell>{rankAtRetirement}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer component={Paper} style={{marginTop:"15px", width:"60%", position:"relative", left:"20%"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Part-time Service</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Start Date</TableCell>
                                        <TableCell>End Date</TableCell>
                                        <TableCell>Percentage (%)</TableCell>
                                    </TableRow>
                                    {partTime.map((period, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{moment(period['from']).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell>{moment(period['to']).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell>{period['proportion']}</TableCell>                                                   
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded==='panel2'} onChange={handleAccordionChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Retirement Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <div>
                    <Carousel className="carousel__main"
                        onChange={ (next) => setCurrentPanel(next) }
                        navButtonsAlwaysVisible={titles.length === 1 ? false : true} 
                        autoPlay={false} 
                        animation={"slide"}
                        
                        NextIcon = {<ArrowRightIcon className="carousel__right__arrow" />}
                        PrevIcon = {<ArrowLeftIcon className="carousel__left__arrow" />}
                        index = {titles.length === 1 ? 0 : titles.length-1}
                        indicators={titles.length === 1 ? false : true}
                        navButtonsProps={{
                            style: {
                                backgroundColor: titles.length !== 1 ? '#af292e' : '#FFFFFF',
                        }
                        }}
                        navButtonsWrapperProps={{
                            style: {
                                position:'fixed',
                                marginLeft: '10%',
                                marginRight: '10%'
                            }
                        }}
                        indicatorContainerProps={{
                            style: {
                                marginTop: '0px',
                            }
                
                        }}
                        indicatorIconButtonProps={{
                            style: {
                                color: '#af292e50'
                            }
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                backgroundColor: '#af292e'
                            }
                        }}
                    >
                        
                    

                        {titles.map( (title, i) => 
                        
                            title !== "Comparison chart" ? (

                            <div className="masterpanel__container" key={i}>
                                <CarouselPanel 
                                panelTitle={title === "Leave scheme" ? "If you leave the scheme before retirement" : title}
                                annualPension={[local_annualPensionNominal[i], local_annualPensionReal[i], local_annualPensionProportional[i]]} 
                                lumpSum={[local_lumpSumNominal[i], local_lumpSumReal[i], local_lumpSumProportional[i]]}
                                viewOption={viewOption} 
                                legendLabels={legendLabelsCarousel} 
                                loading={loading}
                                titleInfoText={titleInfoText[title]} 
                                cpi={local_cpi}
                                scheme={scheme}
                                />
                                <Box width="50%" className="slider__container">

                                <SliderWithSideButtonSingle
                                                key={"A"+i}
                                                parameter={local_cpi}
                                                setParameter={local_setCpi}
                                                parameterName="Salary increase (%)"
                                                parameterMinValue={cpiMin}
                                                parameterMaxValue={cpiMax} 
                                                parameterDisplayMultiplicationFactor={cpiDisplayMultiplicationFactor}
                                                parameterRoundingDP={cpiRoundingDP}
                                                parameterStepSize={cpiStepSize}
                                                parameterInformation={cpiInformation}
                                                requestUpdatedData={requestUpdatedData_cpi}
                                            />

                                {/* {!isCpiEdit ? (
                                    <div>
                                        <span>Estimations are based on { (local_cpi * cpiDisplayMultiplicationFactor).toFixed(cpiRoundingDP) }% CPI inflation. </span>
                                        <span id="slider__edit__cpi" onClick={handleCpiEditClick}>Edit.</span> 
                                    </div>
                                    ) : (
                                    <div>
                                        <div>
                                            <span>Estimations are based on { (local_cpi * cpiDisplayMultiplicationFactor).toFixed(cpiRoundingDP) }% CPI inflation. </span>
                                            <span id="slider__edit__cpi" onClick={handleCpiEditClick}>Hide.</span> 
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel 
                                                    control={<Checkbox style={{color:"#af292e"}} defaultChecked={overwriteSalary} />} 
                                                    label="Set Salary increase to 0? (Please click on the tick box and not the text)" 
                                                    onClick={() => handleOverwriteSalary()}
                                                    />
                                                </FormGroup>
                                            </div> 
                                            <SliderWithSideButtonSingle
                                                parameter={local_cpi}
                                                setParameter={local_setCpi}
                                                parameterName="CPI (%)"
                                                parameterMinValue={cpiMin}
                                                parameterMaxValue={cpiMax} 
                                                parameterDisplayMultiplicationFactor={cpiDisplayMultiplicationFactor}
                                                parameterRoundingDP={cpiRoundingDP}
                                                parameterStepSize={cpiStepSize}
                                                parameterInformation={cpiInformation}
                                                requestUpdatedData={requestUpdatedData_cpi}
                                            />
                                        </div>
                                    </div>
                                    )} */}
                                    {/* <br></br> */}

                                    {(title.includes("25 years") || title.replace("pensionable ","") === "Retirement at 30 years of service") && (
                                        retirementAges[currentPanel] < 55 && (
                                            <SliderWithSideButtons
                                                key={"B"+i}
                                                currentPanel={currentPanel}
                                                parameter={local_careAges}
                                                setParameter={local_setCareAges}
                                                parameterName="Age to receive 2015 Scheme pension"
                                                parameterMinValue={55}
                                                parameterMaxValue={ageSPA} 
                                                parameterDisplayMultiplicationFactor={1}
                                                parameterRoundingDP={0}
                                                parameterStepSize={1}
                                                parameterInformation={CAREAgeInformation}
                                                requestUpdatedData={requestUpdatedData_careAges}
                                            />
                                        ) 
                                    )}

                                    {title === "Leave scheme" && (
                                        <SliderWithSideButtons
                                            key={"C"+i}
                                            currentPanel={currentPanel}
                                            parameter={local_deferredAge}
                                            setParameter={local_setDeferredAge}
                                            parameterName="Age to leave the scheme"
                                            parameterMinValue={minDeferredAge}
                                            parameterMaxValue={maxDeferredAge} 
                                            parameterDisplayMultiplicationFactor={1}
                                            parameterRoundingDP={0}
                                            parameterStepSize={1}
                                            parameterInformation={deferredAgeInformation}
                                            requestUpdatedData={requestUpdatedData_defferedAges}
                                        />
                                    )}
                                    {local_maxCommutationPrevScheme[0] > 0 && 
                                    <SliderWithSideButtons
                                        key={"D"+i}
                                        currentPanel={currentPanel}
                                        parameter={local_chosenCommutationProportionPrevSchemeAdj}
                                        setParameter={local_setChosenCommutationProportionPrevSchemeAdj}
                                        parameterName={prevSchemeCommutationLabel}
                                        parameterMinValue={commutationMin}
                                        parameterMaxValue={1} 
                                        parameterDisplayMultiplicationFactor={commutationDisplayMultiplicationFactor}
                                        parameterRoundingDP={commutationRoundingDP}
                                        parameterStepSize={commutationStepSize}
                                        parameterInformation={commutation1987Information}
                                        requestUpdatedData={requestUpdatedData_prevSchemeCommutation}
                                    />}
                                    {local_maxCommutationCurrScheme[0] > 0 &&
                                    <SliderWithSideButtons
                                        key={"E"+i}
                                        currentPanel={currentPanel}
                                        parameter={local_chosenCommutationProportionCurrSchemeAdj}
                                        setParameter={local_setChosenCommutationProportionCurrSchemeAdj}
                                        parameterName="Cash lump sum in 2015 Scheme (%)"
                                        parameterMinValue={commutationMin}
                                        parameterMaxValue={1} 
                                        parameterDisplayMultiplicationFactor={commutationDisplayMultiplicationFactor}
                                        parameterRoundingDP={commutationRoundingDP}
                                        parameterStepSize={commutationStepSize}
                                        parameterInformation={commutation2015Information}
                                        requestUpdatedData={requestUpdatedData_currSchemeCommutation}
                                    />}

                                    </Box>
                                </div>
                                ) : (
                                    titles.length !== 2 && (
                                    <div className="masterpanel__container" key={i}>
                                        <ComparisonChart 
                                            annualPension={[local_annualPensionNominal, local_annualPensionReal, local_annualPensionProportional]} 
                                            lumpSum={[local_lumpSumNominal, local_lumpSumReal, local_lumpSumProportional]}
                                            lumpSumComparisonChart={local_lumpSumComparisonChart}
                                            viewOption={viewOption}
                                            loading={loading}
                                            titleInfoText={titleInfoTextComparisonChart}
                                            titles={titles}
                                            transitionYear={local_transitionYear}
                                            scheme = {scheme}
                                        />
                                        <div className="radio__container__summary">
                                            <Typography sx={{ mt: 2, mb: 1 }}>Scheme benefits accrued for the period 1 April 2015 to 31 March 2022</Typography>
                                            <div className="radiobuttons__region">
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        row
                                                        aria-label="region"
                                                        name="radio-buttons-group"
                                                        >
                                                        <FormControlLabel 
                                                        value="2022"
                                                        checked={local_transitionYear==="2022"}
                                                        control={<Radio style={{color:colorTheme}} />} 
                                                        label="Legacy"
                                                        onClick={() => local_setTransitionYear('2022')} />
                                                        <FormControlLabel 
                                                        value="2015" 
                                                        checked={local_transitionYear==="2015"}
                                                        control={<Radio style={{color:colorTheme}} />} 
                                                        label="Reformed"
                                                        onClick={() => local_setTransitionYear('2015')} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <br/>
                                        </div> 
                                    </div>
                                    
                                    )

                                )
                            )
                        }


                    </Carousel>
                    </div>
                    </AccordionDetails>
                </Accordion>
                
                {/* <Accordion expanded={expanded==='panel3'} onChange={handleAccordionChange('panel3')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Tabular Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        {(tableData.length && currentPanel !== titles.length -1) ? (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}> 
                        <TableContainer className="yourchoice__table" component={Paper} style={{marginTop:"20px", marginBottom:"20px", width:"48%"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{borderBottom: "2px solid black"}}></TableCell>
                                        <TableCell style={{borderLeft: "2px solid black", borderBottom:"2px solid black",fontWeight:"bold"}}>Annual Pension</TableCell>
                                        <TableCell style={{borderBottom: "2px solid black"}}></TableCell>
                                    </TableRow>                            
                                </TableHead>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:"bold"}}>Age</TableCell>
                                        <TableCell style={{borderLeft: "2px solid black", fontWeight:"bold"}}>CARE in 2015</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>CARE in 2022</TableCell>
                                    </TableRow>                            
                                </TableHead>
                                <TableBody>
                                {tableData[0][currentPanel] !== null && (
                                    tableData[0][currentPanel].map((row, r) => (
                                    <TableRow key={r}>
                                        <TableCell>{row['Age']}</TableCell>
                                        {viewOption !== 2 ? (
                                        <TableCell style={{borderLeft: "2px solid black"}}>
                                            {'£' + Math.round(row['Move to CARE in 2015']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </TableCell>
                                        ) : (
                                        <TableCell style={{borderLeft: "2px solid black"}}>
                                            {(row['Move to CARE in 2015']*100).toFixed()} %
                                        </TableCell>
                                        )}
                                        {viewOption !== 2 ? (
                                        <TableCell>
                                            {'£' + Math.round(row['Move to CARE in 2022']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </TableCell>
                                        ) : (
                                        <TableCell>
                                            {(row['Move to CARE in 2022']*100).toFixed()} %
                                        </TableCell>
                                        )}
                                    </TableRow>
                                )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableContainer className="yourchoice__table" component={Paper} style={{marginTop:"20px", marginBottom:"20px", width:"48%"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{borderBottom: "2px solid black"}}></TableCell>
                                        <TableCell style={{borderLeft: "2px solid black", borderBottom:"2px solid black",fontWeight:"bold"}}>Lump Sum</TableCell>
                                        <TableCell style={{borderBottom: "2px solid black"}}></TableCell>
                                    </TableRow>                            
                                </TableHead>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:"bold"}}>Age</TableCell>
                                        <TableCell style={{borderLeft: "2px solid black", fontWeight:"bold"}}>CARE in 2015</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>CARE in 2022</TableCell>
                                    </TableRow>                            
                                </TableHead>
                                <TableBody>
                                {tableData[1][currentPanel] !== null && (
                                    tableData[1][currentPanel].map((row, r) => (
                                    <TableRow key={r}>
                                        <TableCell>{row['Age']}</TableCell>
                                        {viewOption !== 2 ? (
                                        <TableCell style={{borderLeft: "2px solid black"}}>
                                            {'£' + Math.round(row['Move to CARE in 2015']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </TableCell>
                                        ) : (
                                        <TableCell style={{borderLeft: "2px solid black"}}>
                                            {(row['Move to CARE in 2015']*100).toFixed()} %
                                        </TableCell>
                                        )}
                                        {viewOption !== 2 ? (
                                        <TableCell>
                                            {'£' + Math.round(row['Move to CARE in 2022']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </TableCell>
                                        ) : (
                                        <TableCell>
                                            {(row['Move to CARE in 2022']*100).toFixed()} %
                                        </TableCell>
                                        )}
                                    </TableRow>
                                )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </div>
                        ) : (tableData.length && currentPanel === titles.length -1) && (
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                            <TableContainer className="yourchoice__table" component={Paper} style={{marginTop:"20px", marginBottom:"20px", width:"96%"}}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{borderLeft: "2px solid black", borderBottom:"2px solid black",fontWeight:"bold"}}>Annual Pension</TableCell>
                                            {titles.map((title, i) => (
                                                title !== "Comparison chart"  && (
                                                    <TableCell key={i} style={{borderBottom: "2px solid black"}}></TableCell>
                                                )
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{fontWeight:"bold"}}>Age</TableCell>
                                            {titles.map((title, i) => (
                                                title !== "Comparison chart"  && (
                                                    <TableCell key={i} style={{fontWeight:"bold"}}>{title}</TableCell>
                                                )
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData[0][0] !== null && (
                                            tableData[0][0].map((pensionData, i) => (
                                            <TableRow key={i}>
                                                <TableCell>{pensionData['Age']}</TableCell>
                                                {titles.map((title, j) => (
                                                    title !== "Comparison chart" && (
                                                    <TableCell key={j}>{'£' + Math.round(tableData[0][j][i]['Move to CARE in 2022']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                                    )
                                                ))}
                                            </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableContainer className="yourchoice__table" component={Paper} style={{marginTop:"20px", marginBottom:"20px", width:"96%"}}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{borderLeft: "2px solid black", borderBottom:"2px solid black",fontWeight:"bold"}}>Lump sum</TableCell>
                                            {titles.map((title, i) => (
                                                title !== "Comparison chart"  && (
                                                    <TableCell key={i} style={{borderBottom: "2px solid black"}}></TableCell>
                                                )
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{fontWeight:"bold"}}>Age</TableCell>
                                            {titles.map((title, i) => (
                                                title !== "Comparison chart"  && (
                                                    <TableCell key={i} style={{fontWeight:"bold"}}>{title}</TableCell>
                                                )
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {tableData[0][0] !== null && (
                                            tableData[0][0].map((ageData, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{ageData['Age']}</TableCell>
                                                    {titles.map((title, k) => (
                                                        title !== "Comparison chart" && (
                                                            (ageData['Age'] === careAges[0] && tableData[1][k].length === 2) ? (
                                                                <TableCell key={k}>{'£' + Math.round(tableData[1][0][1]['Move to CARE in 2022']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                                            ) : ageData['Age'] === tableData[1][k][0]['Age'] ? (
                                                                <TableCell key={k}>{'£' + Math.round(tableData[1][k][0]['Move to CARE in 2022']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                                            ) : (
                                                                <TableCell key={k}>0</TableCell>
                                                            )
                                                        )
                                                    ))}
                                                </TableRow>
                                            ))
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                        )}
                    </AccordionDetails>
                </Accordion> */}

            </div>

        </div>

    )
}

export default YourChoice
